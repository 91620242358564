@use 'bp';

@import 'fonts';
@import 'palette';
@import 'reset';

/*
 * Use only a HTML selectors for each definition.
 */

html,
body {
    display: flex;
    flex-direction: column;
    min-width: 100%;
}

html {
    min-height: 100%;
}

html,
body,
#__next {
    height: 100%;
}

body {
    flex: 1 1 100%;
    font-family: $ff-interstate-regular;
    font-size: $fs-0;

    &.asideOpen {
        overscroll-behavior: none;
    }

    @media (min-width: bp.$bp-sm) {
        overflow: hidden;
    }
}

#scrollContainer {
    scroll-behavior: smooth;
}

a {
    color: $brand-color;
    cursor: pointer;
    text-decoration: none;
}

ol,
ul {
    list-style: none;
}
