html, body,
h1, h2, h3, h4, h5, h6,
p, ol, ul {
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

* {
    box-sizing: border-box;
}
